import '@blum-rivendell/reactui/dist/styles.css';
import '../../styles/global.css';
import { Amplify, Auth } from 'aws-amplify';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { ReactElement, ReactNode, useEffect } from 'react';
import awsConfig from '../aws-exports';
import Providers from 'src/Providers';
import hotjar from '../hotjar';
import AmplitudeAnalytics from 'src/reports/analytics/AmplitudeAnalytics';

declare global {
  interface Window {
    $zoho: any;
  }
}

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

let configAmplify: any = {
  Auth: awsConfig.Auth,
  API: {
    endpoints: [
      {
        name: 'BlumAPI',
        endpoint: awsConfig.API.blumApiEndpoint,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        }),
      },
    ],
  },
};

if (typeof window !== 'undefined') {
  configAmplify.storage = sessionStorage;
}

Amplify.configure(configAmplify);
Auth.configure(configAmplify);

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  useEffect(() => {
    hotjar(2446192, 6);
    AmplitudeAnalytics.initAnalytics();
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  return <Providers>{getLayout(<Component {...pageProps} />)}</Providers>;
}

export default MyApp;
