import * as React from 'react';
import useBusiness from 'src/hooks/useBusiness';
import Loader from 'src/components/molecules/Loader';
import {
  CustomerBusinessI,
  CustomerBusinessStatus,
} from 'blum-rivendell-frontend/dist/customer/domain/CustomerBusiness';
import SelectCompany from 'src/components/organisms/forms/SelectCompany';
import { PayloadI } from 'src/business/Customer/domain/Payload';
import { useRouter } from 'next/router';
import { privateRoutes } from 'src/routes';

interface CustomerForSelect extends Pick<CustomerBusinessI, 'id' | 'name'> {}

export interface AuthContextProps {
  isAuthenticated: boolean | undefined;
  isLoading: boolean;
  customer: CustomerBusinessI | null;
  customers: CustomerForSelect[];
  selectCustomer: (customerId: string) => void;
  isLoadingReadCustomer: boolean;
  reloadCustomer: () => void;
  payload: PayloadI;
  reloadPayload: () => void;
  setIsAuthenticated: (boolean) => void;
  onSignIn: (email: string) => void;
  onSignOut: () => void;
}

export const AuthContext = React.createContext<AuthContextProps | undefined>(
  undefined
);

export const AuthContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [customers, setCustomers] = React.useState<CustomerForSelect[]>([]);
  const [isAuthenticated, setIsAuthenticated] = React.useState<
    boolean | undefined
  >(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const router = useRouter();

  const {
    data: customer,
    isLoading: isLoadingReadCustomer,
    run: runReadCustomer,
    resetData: resetCustomer,
  } = useBusiness<CustomerBusinessI>({
    initState: null,
  });

  React.useEffect(() => {
    if (customer) {
      if (
        customer.status === CustomerBusinessStatus.ONBOARDING &&
        router.pathname !== privateRoutes.onboarding.path
      ) {
        router.replace(privateRoutes.onboarding.path);
      }

      if (
        customer.status !== CustomerBusinessStatus.ONBOARDING &&
        router.pathname === privateRoutes.onboarding.path
      ) {
        router.replace(privateRoutes.home.path);
      }
    }
  }, [customer]);

  const { run: runCurrentCustomer } = useBusiness();

  const { run: runGetCustomersForSelect, isLoading: isLoadingGetCustomers } =
    useBusiness();

  const getCustomersForSelect = (ids: string[]) => {
    runGetCustomersForSelect(async (b) => {
      const customers = await Promise.all(
        ids.map((id) => b.customerRivendell.getCustomer(id))
      );

      setCustomers(customers.map(({ id, name }) => ({ id, name })));
    });
  };

  const {
    data: dataPayload,
    isLoading: isLoadingPayload,
    run: runGetPayload,
  } = useBusiness({
    initRun: (b) => b.customer.getPayload(),
    onSuccess: (data) => {
      getCustomersForSelect(data['custom:customerIds'].split(','));
      setIsAuthenticated(true);
      setIsLoading(false);
    },
    onError: () => {
      setIsAuthenticated(false);
      setIsLoading(false);
    },
  });

  const { run: runSignOut } = useBusiness({
    onSuccess: () => {
      setIsAuthenticated(false);
    },
  });

  const onSignOut = async () => {
    try {
      window?.$zoho?.salesiq?.reset?.();
    } catch {
      console.log('error en zoho');
    }
    await runSignOut((b) => b.customer.signOut());
    await runCurrentCustomer((b) => {
      b.currentCustomer.reset();
      b.currentCustomerRivendell.reset();
    });
    resetCustomer();
    setCustomers([]);
  };

  const onSignIn = async (email?: string) => {
    try {
      window?.$zoho?.salesiq?.visitor?.email?.(email || '');
    } catch {
      console.log('error en zoho');
    }
    runGetPayload((b) => b.customer.getPayload());
    setIsAuthenticated(true);
  };

  const reloadPayload = async () => {
    runGetPayload((b) =>
      b.customer.getPayload({
        updatedPayload: true,
      })
    );
  };

  const reloadCustomer = async () => {
    runReadCustomer(
      (b) =>
        b.customerRivendell.getCustomer(
          customer.id
        ) as Promise<CustomerBusinessI>
    );
  };

  const selectCustomer = (customerId: string) => {
    runCurrentCustomer((b) => {
      b.currentCustomer.updateId(customerId);
      b.currentCustomerRivendell.updateId(customerId);
    });
    runReadCustomer(
      (b) =>
        b.customerRivendell.getCustomer(
          customerId
        ) as Promise<CustomerBusinessI>
    );
  };

  if (isLoadingPayload || isLoadingReadCustomer || isLoadingGetCustomers)
    return <Loader />;

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        onSignIn,
        onSignOut,
        setIsAuthenticated,
        customer,
        customers,
        selectCustomer,
        isLoadingReadCustomer,
        reloadCustomer,
        payload: dataPayload,
        reloadPayload,
      }}
    >
      {isAuthenticated && !customer ? <SelectCompany /> : <>{children}</>}
    </AuthContext.Provider>
  );
};

export const useContextAuth = () => {
  const data = React.useContext(AuthContext);

  if (data === undefined) {
    throw new Error('useContextAuth must be used inside AuthContextProvider');
  }

  return data;
};
