import { FetcherAmplify } from 'src/business/Libraries/Fetcher/FetcherAmplify';
import { ApprovalI, ApprovalType } from '../domain';
import SecurityRepositoryI from './SecurityRepositoryI';

export default class SecurityRepositoryImpl implements SecurityRepositoryI {
  constructor(private fetcher: FetcherAmplify) {}

  public async getApprovals(
    customerId: string,
    type: ApprovalType
  ): Promise<ApprovalI[]> {
    return this.fetcher.get(`/v1/customers/${customerId}/approvals`, {
      queryStringParameters: {
        type,
      },
    });
  }
}
