export const privateRoutes = {
  home: {
    path: '/home',
    title: 'Dashboard',
  },
  simulator: {
    path: '/simulator',
    title: 'Simulador',
  },
  onboarding: {
    path: '/onboarding',
    title: 'Registro',
  },
  movements: {
    path: '/movements',
    title: 'Movimientos',
  },
  rescue: {
    path: '/rescue',
    title: 'Retiros',
  },
  configuration: {
    path: '/configuration',
    title: 'Configuración',
    changePassword: {
      fullpath: '/configuration/security',
      path: '/security',
      title: 'Contraseña',
    },
    account: {
      path: '/account',
      fullpath: '/configuration/account',
      title: 'Cuentas Bancarias',
      add: {
        fullpath: '/configuration/account/add',
        path: '/account/add',
        title: '',
      },
    },
    employees: {
      path: '/employees',
      fullpath: '/configuration/employees',
      title: 'Empleados',
    },
  },
  funding: {
    path: '/funding/add-subscription',
    title: '',
  },
  learn: {
    path: '/learn',
    title: 'Aprende',
  },
};

export const publicRoutes = {
  signin: {
    path: '/',
    title: '',
  },
  forgotPassword: {
    path: '/forgot-password',
    title: '',
  },
  createCustomer: {
    path: '/create-customer',
    title: '',
  },
};
