import { FetcherAmplify } from 'src/business/Libraries/Fetcher/FetcherAmplify';
import { CustomerI } from '../domain/Customer';
import CustomerRepositoryI from './CustomerRepositoryI';

export default class CustomerRepositoryImpl implements CustomerRepositoryI {
  private fetcher;

  constructor(fetcher: FetcherAmplify) {
    this.fetcher = fetcher;
  }

  private async saveFile(url: string, fields: any, file: any) {
    const formData = new FormData();
    const keys = [
      'Content-Type',
      'key',
      'bucket',
      'X-Amz-Algorithm',
      'X-Amz-Credential',
      'X-Amz-Date',
      'X-Amz-Security-Token',
      'Policy',
      'X-Amz-Signature',
    ];
    keys.forEach((name) => {
      formData.append(name, fields[name]);
    });
    formData.append('file', file);
    return fetch(url, {
      method: 'POST',
      body: formData,
    });
  }

  public async readCustomer(id: string): Promise<CustomerI> {
    const customer: CustomerI = await this.fetcher.get(`/v1/customers/${id}`, {
      queryStringParameters: {
        fullData: true,
      },
    });
    return customer;
  }

  public async createOtp(
    customerId: string,
    type: string
  ): Promise<{ id: string }> {
    const response = await this.fetcher.post(
      `/v1/customers/${customerId}/otp`,
      {
        body: {
          type,
        },
      }
    );
    return response;
  }

  public async verifyOtp(
    customerId: string,
    otpId: string,
    code: string
  ): Promise<void> {
    await this.fetcher.put(`/v1/customers/${customerId}/otp/${otpId}`, {
      body: {
        code,
      },
    });
  }
}
