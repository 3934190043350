import { FetcherAmplify } from 'src/business/Libraries/Fetcher/FetcherAmplify';
import RecomendationsRepositoryI from './RecommendationsRepositoryI';

export default class RecommendationsRepository
  implements RecomendationsRepositoryI
{
  private fetcher;

  constructor(fetcher: FetcherAmplify) {
    this.fetcher = fetcher;
  }

  public async getRecommendedFunds({
    customerId,
    riskProfile,
    targetDate,
  }: {
    customerId: string;
    riskProfile: string;
    targetDate: string;
  }): Promise<any[]> {
    const response = await this.fetcher.get(
      `/v1/customers/${customerId}/recommendations/funds`,
      {
        queryStringParameters: {
          riskProfile,
          targetDate,
        },
      }
    );
    return response;
  }

  public async getRecommendedSeries({
    customerId,
    fundId,
    amount,
    currency,
  }: {
    customerId: string;
    fundId: string;
    amount: number;
    currency: string;
  }): Promise<any[]> {
    const response = await this.fetcher.get(
      `/v1/customers/${customerId}/recommendations/series`,
      {
        queryStringParameters: {
          fundId,
          amount,
          currency,
        },
      }
    );
    return response;
  }
}
