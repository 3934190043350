import { EmployeeI } from '../domain/Employee';
import EmployeesRepositoryI, {
  CreateEmployeeReqI,
  UpdateEmployeeReqI,
} from '../repository/EmployeesRepositoryI';
import EmployeeServiceI from './EmployeeServiceI';

export default class EmployeeService implements EmployeeServiceI {
  private employeesRepository: EmployeesRepositoryI;

  constructor(employeesRepository: EmployeesRepositoryI) {
    this.employeesRepository = employeesRepository;
  }

  private RolName = {
    OWNER: 'Apoderado',
    ADMIN: 'Admin',
    USER: 'Visualizador',
  };

  public async createEmployee({
    customerId,
    employee,
  }: {
    customerId: string;
    employee: CreateEmployeeReqI;
  }): Promise<void> {
    return this.employeesRepository.createEmployee({
      customerId,
      employee,
    });
  }

  public async getEmployees({
    customerId,
  }: {
    customerId: string;
  }): Promise<EmployeeI[]> {
    const employees = await this.employeesRepository.getEmployees(customerId);

    return employees.map((emp) => ({
      ...emp,
      roles: emp?.roles?.map((rol) => ({
        rol: rol as string,
        rolName: this.RolName[rol as keyof typeof this.RolName] as string,
      })),
    }));
  }

  public async getEmployee(params: {
    employeeId: string;
    customerId: string;
  }): Promise<EmployeeI> {
    const response = await this.employeesRepository.getEmployee(
      params.customerId,
      params.employeeId
    );

    return {
      ...response,
      roles: response?.roles.map((rol) => ({
        rol: rol as string,
        rolName: this.RolName[rol as keyof typeof this.RolName] as string,
      })),
    };
  }

  public async updateEmployee({
    customerId,
    employee,
    employeeId,
  }: {
    employeeId: string;
    customerId: string;
    employee: UpdateEmployeeReqI;
  }): Promise<void> {
    return this.employeesRepository.updateEmployee({
      customerId,
      employeeId,
      employee,
    });
  }
}
