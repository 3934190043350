import amplitude from 'amplitude-js';

const initAnalytics = (): void => {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY || '');
};

const setUser = (idUser: string): void => {
  amplitude.getInstance().setUserId(idUser);
};

const registerEvent = (
  event: string,
  properties?: { [key: string]: any }
): void => {
  amplitude.getInstance().logEvent(event, properties);
};

const registerSuccessEvent = (
  event: string,
  properties?: { [key: string]: any }
): void => {
  registerEvent(`B2B:${event}:done`, properties);
};

const registerErrorEvent = (
  event: string,
  properties?: { [key: string]: any }
): void => {
  registerEvent(`B2B:${event}:failed`, properties);
};

const registerInitEvent = (
  event: string,
  properties?: { [key: string]: any }
): void => {
  registerEvent(`B2B:${event}:init`, properties);
};

const AmplitudeAnalytics: {
  initAnalytics: () => void;
  setUser: (idUser: string) => void;
  registerEvent: (event: string, properties?: { [key: string]: any }) => void;
  registerSuccessEvent: (
    event: string,
    properties?: { [key: string]: any }
  ) => void;
  registerErrorEvent: (
    event: string,
    properties?: { [key: string]: any }
  ) => void;
  registerInitEvent: (
    event: string,
    properties?: { [key: string]: any }
  ) => void;
} = {
  initAnalytics,
  setUser,
  registerEvent,
  registerErrorEvent,
  registerSuccessEvent,
  registerInitEvent,
} as any;

export default AmplitudeAnalytics;
