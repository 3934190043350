import { FetcherAmplify } from 'src/business/Libraries/Fetcher/FetcherAmplify';
import QuizRepositoryI from './QuizRepositoryI';

export default class QuizRepositoryImpl implements QuizRepositoryI {
  constructor(private fetcher: FetcherAmplify) {}

  public async create(): Promise<any> {
    return this.fetcher.post('/v1/quizzes');
  }

  public async update(idQuiz: string, questions: any[]): Promise<any> {
    return this.fetcher.put(`/v1/quizzes/${idQuiz}`, {
      body: {
        questions,
      },
    });
  }
}
