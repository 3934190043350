import { UnexpectedError } from 'src/business/exception/UnexpectedError';
import { CustomerI } from '../domain/Customer';
import {
  RecommendedFundsI,
  RecommendedSeriesI,
} from '../domain/Recommendations';
import AuthRepositoryI, {
  CustomerCreateI,
} from '../repository/AuthRepositoryI';
import CustomerRepositoryI from '../repository/CustomerRepositoryI';
import RecommendationsRepositoryI from '../repository/RecommendationsRepositoryI';
import CustomerServiceI from './CustomerServiceI';
import { PayloadI } from '../domain/Payload';
import { v1 as uuidv1 } from 'uuid';

export default class CustomerService implements CustomerServiceI {
  private userRepository: CustomerRepositoryI;
  private authRepository: AuthRepositoryI;
  private recommendationRepository: RecommendationsRepositoryI;

  private fundsName: { [key: string]: string } = {
    globalGrowth: 'Blum Renta Global',
    globalCapital: 'Blum Capital Global',
    globalCash: 'Blum Cash',
  };

  constructor(
    userRepository: CustomerRepositoryI,
    authRepository: AuthRepositoryI,
    recomendationRepository: RecommendationsRepositoryI
  ) {
    this.userRepository = userRepository;
    this.authRepository = authRepository;
    this.recommendationRepository = recomendationRepository;
  }

  private removeEmptyFromObj(obj: Object) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.removeEmptyFromObj(obj[key]);
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      } else if (
        obj[key] == null ||
        obj[key] === '' ||
        (Array.isArray(obj[key]) && obj[key].length === 0)
      ) {
        delete obj[key];
      } else if (Array.isArray(obj[key]) && obj[key].length > 0) {
        this.removeEmptyFromObj(obj[key]);
      }
    });
    return obj;
  }

  public saveParticipantId(code: string): void {
    this.authRepository.saveParticipantId(code);
  }

  public findParticipantId(): string | null {
    return this.authRepository.findParticipantId();
  }

  public removeParticipantId(): void {
    this.authRepository.removeParticipantId();
  }

  public async createCustomer(
    customer: CustomerCreateI,
    password: string
  ): Promise<{ email: string }> {
    try {
      await this.authRepository.signUp(customer, password, uuidv1());
      return {
        email: customer.email,
      };
    } catch (e: any) {
      if (e.code === 'UsernameExistsException') {
        throw new Error('Cuenta ya registrada');
      }
      throw new UnexpectedError();
    }
  }

  public async resendCodeSignUp(email: string): Promise<void> {
    await this.authRepository.resendCodeSignUp(email);
  }

  public async signIn({
    email,
    captcha,
    password,
  }: {
    email: string;
    captcha: string;
    password: string;
  }): Promise<{
    id: string;
    name: string;
    status: string;
    nextAction: string;
    challenge?: any;
  }> {
    let response;
    try {
      response = await this.authRepository.signIn(email, captcha, password);
    } catch (e: any) {
      if (
        e.code === 'NotAuthorizedException' ||
        e.code === 'UserNotFoundException'
      ) {
        throw new Error('Correo o contraseña incorrecta');
      }
      if (e.code === 'UserNotConfirmedException') {
        throw new Error('Correo electrónico no ha sido confirmado.');
      }
      throw new UnexpectedError();
    }

    const payload = response?.signInUserSession?.idToken?.payload;

    return {
      id: payload?.sub,
      name: payload?.given_name,
      status: payload?.['custom:status'],
      nextAction: payload?.['custom:nextAction'],
      ...(response.challengeName === 'NEW_PASSWORD_REQUIRED' && {
        challenge: {
          user: response,
          name: 'NEW_PASSWORD_REQUIRED',
        },
      }),
      ...(response.challengeName === 'CUSTOM_CHALLENGE' && {
        challenge: {
          user: response,
          name: 'CUSTOM_CHALLENGE',
        },
      }),
    };
  }

  public async completeNewPassword({
    cognitoUser,
    newPassword,
  }: {
    cognitoUser: any;
    newPassword: string;
  }) {
    let response = await this.authRepository.completeNewPassword(
      cognitoUser,
      newPassword
    );
    const payload = response?.signInUserSession?.idToken?.payload;

    return {
      id: payload?.sub,
      name: payload?.given_name,
      email: payload?.email,
    };
  }

  public async signOut(): Promise<void> {
    await this.authRepository.signOut();
  }

  // WARNING: don't use - customerId is DEPRECATED
  public async readCustomer(): Promise<CustomerI> {
    const { payload } = (
      await this.authRepository.currentSession()
    ).getIdToken();
    const customer: CustomerI = await this.userRepository.readCustomer(
      payload['custom:customerId']
    );

    return customer;
  }

  public async confirmSignUp({
    email,
    code,
  }: {
    email: string;
    code: string;
  }): Promise<void> {
    try {
      await this.authRepository.confirmSignUp(email, code);
    } catch (e: any) {
      if (e.code === 'CodeMismatchException') {
        throw new Error('Código incorrecto');
      }
      throw new UnexpectedError();
    }
  }

  public async forgotPassword(email: string): Promise<void> {
    try {
      await this.authRepository.forgotPassword(email);
    } catch (e: any) {
      if (e.code === 'UserNotFoundException') {
        throw new Error('Correo inválido');
      }
      if (e.message === 'Attempt limit exceeded, please try after some time.') {
        throw new Error('Llegaste al límite de intentos');
      }
      throw new UnexpectedError();
    }
  }

  public async verifyOtpForgotPassword({
    email,
    code,
    password,
  }: {
    email: string;
    code: string;
    password: string;
  }): Promise<void> {
    try {
      await this.authRepository.forgotPasswordSubmit(email, code, password);
    } catch (e: any) {
      if (e.code === 'CodeMismatchException') {
        throw new Error('Código incorrecto');
      }
      throw new UnexpectedError();
    }
  }

  public async getPayload(params?: {
    updatedPayload: boolean;
  }): Promise<PayloadI> {
    if (params?.updatedPayload) {
      const payload = await this.authRepository.getUpdatedPayload();
      return payload as PayloadI;
    }

    const { payload } = (
      await this.authRepository.currentSession()
    ).getIdToken();

    return payload as PayloadI;
  }

  public async changePassword({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  }): Promise<void> {
    try {
      await this.authRepository.changePassword(oldPassword, newPassword);
    } catch (e: any) {
      if (e.code === 'NotAuthorizedException') {
        throw new Error('Contraseña equivocada');
      }
      if (e.code === 'LimitExceededException') {
        throw new Error('Llegaste al límite de intentos');
      }
      throw new UnexpectedError();
    }
  }

  public async createOtp({
    customerId,
    type,
  }: {
    customerId: string;
    type: 'SELL_TRANSACTION' | '';
  }): Promise<{ id: string }> {
    return this.userRepository.createOtp(customerId, type);
  }

  public async verifyOtp({
    customerId,
    otpId,
    code,
  }: {
    customerId: string;
    otpId: string;
    code: string;
  }): Promise<void> {
    try {
      await this.userRepository.verifyOtp(customerId, otpId, code);
    } catch (error: any) {
      if (error?.response?.data?.message === 'Invalid code') {
        throw new Error('Codigo invalido');
      }
      if (error?.response?.data?.message === 'Otp can no longer be updated') {
        throw new Error('code_expired_or_used');
      }
      throw new Error(error);
    }
  }

  public async getRecommendedFunds(params: {
    customerId: string;
    riskProfile: string;
    targetDate: string;
  }): Promise<RecommendedFundsI[]> {
    const response: any[] =
      await this.recommendationRepository.getRecommendedFunds(params);
    return response.map((fund) => {
      return {
        id: fund?.id,
        percentage: fund?.percentage,
        name: this.fundsName[fund?.id],
      };
    });
  }

  public async getRecommendedSeries(params: {
    customerId: string;
    fundId: string;
    amount: number;
    currency: string;
  }): Promise<RecommendedSeriesI[]> {
    const response: any[] =
      await this.recommendationRepository.getRecommendedSeries(params);
    const names: { [key: string]: string } = {
      SERIES_B: 'Serie B',
      SERIES_A: 'Serie A',
      SERIES_I: 'Serie I',
      NO_SERIES: '',
    };
    return response.map((item) => ({
      series: item.series,
      name: names[item.series],
      recommended: item.recommended,
    }));
  }
}
