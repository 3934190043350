import rivendellBusiness from 'blum-rivendell-frontend';
import { customerService } from './Customer';
import { quizService } from './Quiz';
import { employeeService } from './Employee';
import { contractService } from './Contract';
import { securityService } from './Security';
import { currentCustomer } from './Libraries/CurrentCustomer';
import { chatGPTService } from './ChatGPT';

const business = {
  chatGPT: chatGPTService,
  currentCustomer: currentCustomer,
  customer: customerService,
  employee: employeeService,
  quiz: quizService,
  contract: contractService,
  security: securityService,
  currentCustomerRivendell: rivendellBusiness.currentCustomer,
  bank: rivendellBusiness.bank,
  customerRivendell: rivendellBusiness.customer,
  funds: rivendellBusiness.fund,
  transactionsRivendell: rivendellBusiness.transaction,
  portfolio: rivendellBusiness.portfolio,
  account: rivendellBusiness.account,
  cms: rivendellBusiness.cms,
};

export default business;
