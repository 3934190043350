import { fetcherAmplify } from 'src/business/Libraries/Fetcher';
import AuthRepositoryImpl from './AuthRepositoryImpl';
import CustomerRepositoryImpl from './CustomerRepositoryImpl';
import RecommendationsRepository from './RecommendationsRepositoryImpl';

const customerRepository = new CustomerRepositoryImpl(fetcherAmplify);

const authRepository = new AuthRepositoryImpl();

const recommendationsRepository = new RecommendationsRepository(fetcherAmplify);

export { customerRepository, authRepository, recommendationsRepository };
