import { API } from 'aws-amplify';
import { currentCustomer } from '../CurrentCustomer';

export class FetcherAmplify {
  private scope = 'BlumAPI';

  private fetcher = API;

  public get(endpoint: string, data?: any): Promise<any> {
    return this.fetcher.get(this.scope, endpoint, {
      ...data,
      queryStringParameters: {
        ...data?.queryStringParameters,
        ...(currentCustomer.getId() && {
          customerId: currentCustomer.getId(),
        }),
      },
    });
  }

  public put(endpoint: string, data?: any): Promise<any> {
    return this.fetcher.put(this.scope, endpoint, {
      ...data,
      queryStringParameters: {
        ...data?.queryStringParameters,
        ...(currentCustomer.getId() && {
          customerId: currentCustomer.getId(),
        }),
      },
    });
  }

  public post(endpoint: string, data?: any): Promise<any> {
    return this.fetcher.post(this.scope, endpoint, {
      ...data,
      queryStringParameters: {
        ...data?.queryStringParameters,
        ...(currentCustomer.getId() && {
          customerId: currentCustomer.getId(),
        }),
      },
    });
  }

  public delete(endpoint: string, data?: any): Promise<any> {
    return this.fetcher.del(this.scope, endpoint, {
      ...data,
      queryStringParameters: {
        ...data?.queryStringParameters,
        ...(currentCustomer.getId() && {
          customerId: currentCustomer.getId(),
        }),
      },
    });
  }
}
