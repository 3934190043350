import {
  authRepository,
  customerRepository,
  recommendationsRepository,
} from './repository';
import CustomerService from './service/CustomerServiceImpl';

const customerService = new CustomerService(
  customerRepository,
  authRepository,
  recommendationsRepository
);

export { customerService };
