import { useEffect, useState } from 'react';
import business from 'src/business';

export type StatusType = 'idle' | 'pending' | 'success' | 'error';
export type DomainType = typeof business;
export type RunType<T> = (domain: DomainType) => Promise<T> | T;

export interface IUseDomain<T> {
  initRun?: RunType<T>;
  initState?: any;
  onSuccess?: (data: T) => void;
  onError?: (error: any) => void;
}

const useBusiness = <T>({
  initRun,
  initState = null,
  onSuccess,
  onError,
}: IUseDomain<T> = {}) => {
  const [data, setData] = useState<T>(initState);
  const [status, setStatus] = useState<StatusType>('idle');
  const [error, setError] = useState<any>(null);
  const executeUseCase = async (run: RunType<T>) => {
    setStatus('pending');
    setError(null);
    try {
      const responseData = await run(business);
      responseData && setData(responseData);
      onSuccess && onSuccess(responseData);
      setStatus('success');
    } catch (e: any) {
      if (e === 'No current user') {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      } else if (
        e?.code === 'NotAuthorizedException' &&
        e?.message === 'Refresh Token has expired'
      ) {
        business.customer.signOut();
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      } else if (
        e?.code === 'NotAuthorizedException' &&
        e?.message === 'Refresh Token has been revoked'
      ) {
        business.customer.signOut();
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      }
      setError(e);
      onError && onError(e);
      setStatus('error');
    }
  };

  const resetData = () => {
    setData(initState);
    setStatus('idle');
    setError(null);
  };

  useEffect(() => {
    if (initRun) executeUseCase(initRun);
  }, []);

  return {
    status,
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: !!error,
    isSuccess: status === 'success',
    error,
    data,
    resetData,
    run: executeUseCase,
  };
};

export default useBusiness;
