import { IconBuilding } from '@blum-rivendell/reactui';

interface Props {
  name: string;
  isLoadingSelect?: boolean;
  isSelected?: boolean;
  onClickSelect?: () => void;
}

const CompanyItem = ({ isSelected, name, onClickSelect }: Props) => {
  return (
    <button
      onClick={onClickSelect}
      data-testid={`btn-${name}`}
      className={`bl-border-2  bl-rounded-2xl bl-p-8 ${
        isSelected
          ? 'bl-border-primary-green-100'
          : 'bl-border-neutral-light-80'
      }`}
    >
      <IconBuilding
        className={`bl-w-12 bl-h-12 bl-mx-auto bl-mb-3 lg:bl-mb-6
      ${isSelected ? 'bl-text-primary-green-100' : 'bl-text-neutral-light-80'}
      `}
      />
      <span
        className={`${
          isSelected ? 'bl-text-primary-green-100' : 'bl-text-neutral-light-80'
        }`}
      >
        {name}
      </span>
    </button>
  );
};

export default CompanyItem;
