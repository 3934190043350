import loader from 'src/assets/loader/loader.svg';
import CustomImage from 'src/components/atoms/CustomImage';
import { ModalBase } from '@blum-rivendell/reactui';

const Loader = () => (
  <ModalBase className="bl-flex bl-flex-col bl-justify-center bl-items-center bl-p-4 bl-gap-7 !bl-bg-white/40 bl-backdrop-blur-md">
    <figure className="bl-animate-spin bl-w-36 bl-h-36">
      <CustomImage src={loader} alt="cargando-blum" />
    </figure>
    <span className="bl-text-3xl bl-font-bold bl-text-center">
      Rentabiliza tu dinero al máximo con Blum
    </span>
  </ModalBase>
);

export default Loader;
