const awsConfig = {
  Auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_TWsLWtnFs',
    userPoolWebClientId: '2blu1etr14b7g722j7bit21g67',
  },
  API: {
    blumApiEndpoint: 'https://api.miblum.com',
  },
};

export default awsConfig;
