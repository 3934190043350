import { MessageI } from '../domain';
import ChatGPTRepositoryI from '../repository/ChatGPTRepositoryI';
import ChatGPTServiceI from './ChatGPTServiceI';

export default class ChatGPTServiceImpl implements ChatGPTServiceI {
  constructor(private contractRepository: ChatGPTRepositoryI) {}

  public async findChatgptHistory(
    customerId: string,
    employeeId: string
  ): Promise<MessageI[]> {
    return this.contractRepository.findChatgptHistory(customerId, employeeId);
  }

  public async addQuestion(
    customerId: string,
    employeeId: string,
    question: string
  ): Promise<MessageI> {
    return this.contractRepository.addQuestion(
      customerId,
      employeeId,
      question
    );
  }
}
