import { EmployeeI } from '../domain/Employee';
import EmployeesRepositoryI, {
  CreateEmployeeReqI,
  UpdateEmployeeReqI,
} from './EmployeesRepositoryI';
import { FetcherAmplify } from 'src/business/Libraries/Fetcher/FetcherAmplify';

export default class EmployeesRepositoryImpl implements EmployeesRepositoryI {
  private fetcher;

  constructor(fetcher: FetcherAmplify) {
    this.fetcher = fetcher;
  }

  public async createEmployee({
    customerId,
    employee,
  }: {
    customerId: string;
    employee: CreateEmployeeReqI;
  }): Promise<void> {
    const response = await this.fetcher.post(
      `/v1/customers/${customerId}/employees`,
      {
        body: {
          ...employee,
        },
      }
    );

    return response;
  }

  public async getEmployees(customerId: string): Promise<
    (Omit<EmployeeI, 'roles'> & {
      roles: string[];
    })[]
  > {
    return this.fetcher.get(`/v1/customers/${customerId}/employees`);
  }

  public async getEmployee(
    customerId: string,
    employeeId: string
  ): Promise<any> {
    return this.fetcher.get(
      `/v1/customers/${customerId}/employees/${employeeId}`
    );
  }

  public async updateEmployee({
    customerId,
    employee,
    employeeId,
  }: {
    employeeId: string;
    customerId: string;
    employee: UpdateEmployeeReqI;
  }): Promise<void> {
    return this.fetcher.put(
      `/v1/customers/${customerId}/employees/${employeeId}`,
      {
        body: {
          ...employee,
        },
      }
    );
  }
}
