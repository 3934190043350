import { FetcherAmplify } from 'src/business/Libraries/Fetcher/FetcherAmplify';
import ChatGPTRepositoryI from './ChatGPTRepositoryI';
import { MessageI } from '../domain';

export class ChatgptRepositoryImpl implements ChatGPTRepositoryI {
  constructor(private fetcher: FetcherAmplify) {}

  public async findChatgptHistory(
    customerId: string,
    employeeId: string
  ): Promise<MessageI[]> {
    return this.fetcher.get(
      `/v1/customers/${customerId}/employees/${employeeId}/chatgpt?context=onboarding`
    );
  }

  public async addQuestion(
    customerId: string,
    employeeId: string,
    question: string
  ): Promise<MessageI> {
    return this.fetcher.post(
      `/v1/customers/${customerId}/employees/${employeeId}/chatgpt?context=onboarding`,
      {
        body: {
          question,
        },
      }
    );
  }
}
