import { useEffect, useState } from 'react';
import { Button } from '@blum-rivendell/reactui';
import CompanyItem from './CompanyItem';
import { useContextAuth } from 'src/context/AuthContext';

const FormSelectCompany = () => {
  const { customers, selectCustomer, customer } = useContextAuth();

  useEffect(() => {
    if (customers.length === 1 && !customer) {
      selectCustomer(customers[0].id);
    }
  }, []);

  const [isLoadingSelectCustomer, setIsLoadingSelectCustomer] =
    useState<boolean>(false);

  const [selectedCompanyId, setSelectedCompanyId] = useState(
    customers[0]?.id ?? ''
  );

  const handleSelectCustomer = async () => {
    setIsLoadingSelectCustomer(true);
    await selectCustomer(selectedCompanyId);
  };

  return (
    <>
      <div className="bl-mt-[4%] lg:bl-mt-[7%] xl:bl-mt-[9%] bl-mb-4">
        <h3 className="bl-text-3xl bl-font-bold bl-border-primary-green-100 bl-mb-4">
          Selecciona la empresa
        </h3>
        <p className="bl-mb-4 bl-text-neutral-dark-80">
          Utiliza las opciones para escoger la empresa.
        </p>
        <div className="bl-grid bl-grid-cols-2 sm:bl-grid-cols-3 lg:bl-grid-cols-4 bl-gap-3 bl-pb-10 sm:bl-pb-0">
          {customers.map(({ id, name }) => (
            <CompanyItem
              key={id}
              name={name}
              isLoadingSelect={isLoadingSelectCustomer}
              isSelected={selectedCompanyId === id}
              onClickSelect={() => setSelectedCompanyId(id)}
            />
          ))}
        </div>
      </div>
      <Button
        testId="btn-continue-select-company"
        onClick={handleSelectCustomer}
        isLoading={isLoadingSelectCustomer}
        text="Continuar"
        className="bl-max-w-3 bl-fixed sm:bl-relative bl-self-center md:bl-self-end bl-bottom-3"
      />
    </>
  );
};

export default FormSelectCompany;
