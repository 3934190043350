import Image, { ImageProps } from 'next/image';

interface Props extends ImageProps {}

const CustomImage = ({ alt = '', ...props }: Props) => {
  return (
    <Image loader={({ src }) => `${src}`} alt={alt} unoptimized {...props} />
  );
};

export default CustomImage;
