import { ApprovalI, ApprovalType } from '../domain';
import SecurityRepositoryI from '../repository/SecurityRepositoryI';
import SecurityServiceI from './SecurityServiceI';

export default class SecurityServiceImpl implements SecurityServiceI {
  constructor(private securityRepository: SecurityRepositoryI) {}

  public async getApprovals(params: {
    customerId: string;
    type: ApprovalType;
  }): Promise<ApprovalI[]> {
    return this.securityRepository.getApprovals(params.customerId, params.type);
  }
}
