import { useRouter } from 'next/router';
import Loader from 'src/components/molecules/Loader';
import { useContextAuth } from 'src/context/AuthContext';
import { privateRoutes, publicRoutes } from 'src/routes';

const getprotectedRoutes = (_privateRoutes: any) => {
  let routes: any[] = [];

  Object.values(_privateRoutes).forEach(
    ({ path, title, fullpath, ...rest }: any) => {
      routes.push({ path, title, fullpath });
      let newRest = rest;

      while (Object.values(newRest).length > 0) {
        let newRestNested = {};
        Object.values(newRest).forEach(
          ({ path, title, fullpath, ...newRest2 }: any) => {
            routes.push({ path, title, fullpath });
            newRestNested = { ...newRestNested, ...newRest2 };
          }
        );
        newRest = newRestNested;
      }
    }
  );

  return routes;
};

const protectedRoutes = getprotectedRoutes(privateRoutes);

const ProtectRouteAuth = ({ children }: { children?: React.ReactNode }) => {
  const router = useRouter();
  const { isAuthenticated, isLoading } = useContextAuth();

  const currentPath = router.pathname;

  let isProtected = false;

  for (let i = 0; i < protectedRoutes.length; i++) {
    if (
      currentPath === (protectedRoutes[i] as any)?.path ||
      currentPath === (protectedRoutes[i] as any)?.fullpath
    ) {
      isProtected = true;
      break;
    }
  }

  if (isLoading) return <Loader />;

  if (!isLoading && isProtected && isAuthenticated === false) {
    router.push(publicRoutes.signin.path);
    return <Loader />;
  }

  if (
    !isLoading &&
    (currentPath === '/' ||
      currentPath === '/create-customer' ||
      currentPath === '/forgot-password') &&
    isAuthenticated
  ) {
    router.replace(privateRoutes.home.path);
    return <Loader />;
  }

  return <>{children}</>;
};

export default ProtectRouteAuth;
