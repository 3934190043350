import { CurrentCustomerI } from './CurrentCustomerI';

export class CurrentCustomer implements CurrentCustomerI {
  private static id: string | undefined;
  private static instance: CurrentCustomer;

  private constructor() {}

  public static getInstance(): CurrentCustomer {
    if (!CurrentCustomer.instance) {
      CurrentCustomer.instance = new CurrentCustomer();
    }

    return CurrentCustomer.instance;
  }

  public updateId(customerId: string): void {
    if (customerId === '') {
      throw new Error("Don't update with an empty string");
    }
    CurrentCustomer.id = customerId;
  }

  public getId(): string | undefined {
    return CurrentCustomer.id;
  }

  public reset(): void {
    CurrentCustomer.id = undefined;
  }
}
