import { FetcherAmplify } from 'src/business/Libraries/Fetcher/FetcherAmplify';
import { ContractI } from '../domain';
import ContractRepositoryI from './ContractRepositoryI';

export default class ContractRepositoryImpl implements ContractRepositoryI {
  constructor(private fetcher: FetcherAmplify) {}

  public async createContract(customerId: string): Promise<ContractI> {
    return this.fetcher.post('/v1/contracts', {
      body: {
        customer: {
          id: customerId,
        },
      },
    });
  }

  public async getStatusAssignature(idContract: string): Promise<{
    customer: { id: string };
    status: string;
    error?: {
      code: string;
      message: string;
    };
  }> {
    return this.fetcher.get(`/v1/contracts/${idContract}`);
  }
}
