import styled from 'styled-components';
import UrlSidebarSelectCompany from 'src/assets/select-company-signin/sidebar.png';
import FormSelectCompany from './components/FormSelectCompany';
import { ModalBase } from '@blum-rivendell/reactui';

const DivSidebar = styled.figure`
  background-image: url(${UrlSidebarSelectCompany.src});
`;

const SelectCompany = () => {
  return (
    <ModalBase className="bl-bg-white">
      <div className="bl-w-full bl-h-screen bl-grid bl-grid-cols-[_1fr] md:bl-grid-cols-[_1fr,_2fr] lg:bl-grid-cols-[_1fr,_3fr] xl:bl-grid-cols-[_1fr,_4fr]">
        <section className="bl-hidden md:bl-grid bl-grid-cols-1 bl-grid-rows-6">
          <DivSidebar className=" bl-bg-no-repeat bl-bg-cover bl-bg-clip-padding bl-row-span-4" />
          <div className="bl-bg-[#1D113E] bl-row-span-2 bl-flex bl-flex-col bl-justify-center bl-items-start bl-py-4 bl-px-6 md:bl-px-9 lg:bl-px-13">
            <b className="bl-px-1 bl-bg-white bl-rounded-md bl-mb-2">
              Empresas
            </b>
            <span className="bl-text-4xl bl-font-semibold bl-text-primary-green-50">
              Con Blum
            </span>
            <span className="bl-text-2xl bl-font-medium bl-text-white bl-mb-2">
              crecemos juntos
            </span>
            <p className="bl-text-white">
              Invierte en el extranjero de forma segura y sencilla con nuestro
              equipo de profesionales
            </p>
          </div>
        </section>
        <section className="bl-w-full bl-flex bl-flex-col bl-justify-between bl-py-6 lg:bl-py-13 bl-px-6 lg:bl-px-30">
          <FormSelectCompany />
        </section>
      </div>
    </ModalBase>
  );
};

export default SelectCompany;
