import * as React from 'react';

import { AuthContextProvider } from './context/AuthContext';
import { notifier } from '@blum-rivendell/reactui';
import ProtectRouteAuth from './hoc/ProtectRouteAuth';

const Providers = ({ children }: { children?: React.ReactNode }) => {
  return (
    <AuthContextProvider>
      <ProtectRouteAuth>
        <notifier.NotificationContextProvider>
          <notifier.Notification />
          {children}
        </notifier.NotificationContextProvider>
      </ProtectRouteAuth>
    </AuthContextProvider>
  );
};

export default Providers;
