import { ContractI, StatusAssignatureI } from '../domain';
import ContractRepositoryI from '../repository/ContractRepositoryI';
import ContractServiceI from './ContractServiceI';

export default class ContractServiceImpl implements ContractServiceI {
  constructor(private contractRepository: ContractRepositoryI) {}

  public async createContract(customerId: string): Promise<ContractI> {
    return this.contractRepository.createContract(customerId);
  }

  public async getStatusAssignature(
    idContract: string
  ): Promise<StatusAssignatureI> {
    const response = await this.contractRepository.getStatusAssignature(
      idContract
    );

    return {
      customerId: response.customer.id,
      error: response?.error || undefined,
      status: response.status,
    };
  }
}
