import QuizRepositoryI from '../repository/QuizRepositoryI';
import QuizServiceI from './QuizServiceI';
import { QuizCreatedI, UpdateQuizResponseI } from '../domain';

export default class QuizServiceImpl implements QuizServiceI {
  constructor(private quizRepository: QuizRepositoryI) {}

  public async create(): Promise<QuizCreatedI> {
    return this.quizRepository.create() as Promise<QuizCreatedI>;
  }

  public async update({
    idQuiz,
    questions,
  }: {
    idQuiz: string;
    questions: {
      id: string;
      responses: {
        id: string;
      }[];
    }[];
  }): Promise<UpdateQuizResponseI> {
    return this.quizRepository.update(idQuiz, questions);
  }
}
