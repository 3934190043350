import { Auth } from 'aws-amplify';
import AuthRepositoryI, {
  CustomerCreateI,
  CustomerSignInI,
  ISession,
} from './AuthRepositoryI';

export default class AuthRepositoryImpl implements AuthRepositoryI {
  private auth = Auth;

  private participantId = 'participantId';

  public saveParticipantId(code: string): void {
    window?.localStorage.setItem(this.participantId, code);
  }

  public findParticipantId(): string | null {
    return window?.localStorage.getItem(this.participantId);
  }

  public async currentSession(): Promise<ISession> {
    const session = await this.auth.currentSession();
    return session;
  }

  public async getUpdatedPayload(): Promise<any> {
    const allData = await this.auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    return allData?.signInUserSession?.accessToken?.payload;
  }

  public removeParticipantId(): void {
    window?.localStorage.removeItem(this.participantId);
  }

  public async signUp(
    customer: CustomerCreateI,
    password: string,
    id: string
  ): Promise<void> {
    const params: any = {
      username: customer.email ?? '',
      password,
      attributes: {
        'custom:customerId': id,
        'custom:customerIds': id,
        'custom:documentType': customer.documentType,
        'custom:documentNumber': customer.documentNumber,
        email: customer.email,
        given_name: customer.name,
        phone_number: customer.mobile,
      },
    };
    params.validationData = {
      email: customer.email,
      ruc: customer.ruc,
    };
    await this.auth.signUp(params);
  }

  public async confirmSignUp(email: string, code: string): Promise<void> {
    await this.auth.confirmSignUp(email, code);
  }

  public async resendCodeSignUp(email: string): Promise<void> {
    await this.auth.resendSignUp(email);
  }

  public async signIn(
    email: string,
    captcha: string,
    password: string
  ): Promise<CustomerSignInI> {
    return this.auth.signIn(email, password, { token: captcha });
  }

  public async completeNewPassword(cognitoUser: any, newPassword: string) {
    return this.auth.completeNewPassword(cognitoUser, newPassword);
  }

  public async signOut(): Promise<void> {
    await this.auth.signOut();
  }

  public async forgotPassword(email: string): Promise<void> {
    await this.auth.forgotPassword(email);
  }

  public async forgotPasswordSubmit(
    email: string,
    code: string,
    password: string
  ): Promise<void> {
    await this.auth.forgotPasswordSubmit(email, code, password);
  }

  public async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    const user = await this.auth.currentAuthenticatedUser();
    await this.auth.changePassword(user, oldPassword, newPassword);
    await this.auth.signOut({ global: true });
  }
}
